import React, { useState } from "react"
import Layout from "../../components/Layout/Layout"
import Slider from "react-slick"
import { useMediaQuery } from "react-responsive"

import Page1 from "../../components/EventManagementSlides/Page1"
import Page2 from "../../components/EventManagementSlides/Page2"
import Page3 from "../../components/EventManagementSlides/Page3"
import Page4 from "../../components/EventManagementSlides/Page4"
import Page5 from "../../components/EventManagementSlides/Page5"
const EventManagement = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 720px)",
  })
  const [touch , setTouch] = useState(true)
  var settings = {
    infinite: false,
    arrows: isTabletOrMobileDevice ? false : true,
    touchMove:touch,
    dots: true,
  }
  function handleChange(newValue) {
    setTouch(newValue);
  }
  return (
    <Layout >
        <Slider {...settings}>
         <Page1 onChange={handleChange} />
         <Page2 onChange={handleChange} />
         <Page3 onChange={handleChange} />
         <Page4 onChange={handleChange} />
         <Page5 onChange={handleChange} />
        </Slider>
    </Layout>
  )
}
export default EventManagement
