import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import HomeLayout from "../HomeLayout/HomeLayout"
import CoolLightbox from "../LightBox/CoolLightBox"


const Page1 = ({onChange}) => {
  const [isOpen, setOpen] = useState(false)
  const [currentImageIndex, setCurrentIndex] = useState(0)
  const data = useStaticQuery(graphql`
    query {
      Page1: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "EventManagementPage1" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      EventManagementInfoPage1: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "EventManagementInfoPage1" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  var images = []
  data.EventManagementInfoPage1.nodes.map(img => {
    var src = img.childImageSharp.fluid.src
    images.push({
      src,
    })
  })

  return (
    <HomeLayout>
        <>
        {data.Page1.nodes.map((img, index) => {
            var idx=index
            if(idx==5 ||idx==6)
            return (
              <div
                onClick={() => {
                  setCurrentIndex(idx-5)
                  setOpen(true)
                  onChange(false)
                }}
              >
                <Img fluid={img.childImageSharp.fluid} />
              </div>
            )
            else return <Img fluid={img.childImageSharp.fluid} />
          }
          )}
           <CoolLightbox
           images={images}
           setCurrentIndex={setCurrentIndex}
           currentImageIndex={currentImageIndex}
           isOpen={isOpen}
           onClose={() =>{ setOpen(false) 
            onChange(true)}}
          />
        </>
        </HomeLayout>
  )
}
export default Page1

